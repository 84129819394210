<template>
  <div>
    <div
      v-if="!$gate.isPasswordSetAfterLogin()"
      class="d-flex justify-content-center"
    >
      <form
        class="form-horizontal"
        action=""
        @submit.prevent="sendDetails"
        method="post"
      >
        <div class="card w-100 custom-card-1 m-0 bg-white">
          <fieldset>
            <legend class="d-flex justify-content-start flex-wrap">
              <span>Set Password</span>
            </legend>
            <div class="card-header"></div>

            <div class="card-body">
              <div class="row">
                <div
                  class="col-12 text-center font-weight-bold border-top pt-4 pb-3"
                >
                  <i> You must reset your password. </i>
                </div>
                <div
                  class="col-12 text-danger pb-1 font-weight-bold text-center"
                >
                  <div
                    v-if="form.errors.has('password')"
                    v-html="form.errors.get('password')"
                  />
                </div>

                <div class="col-12 d-flex justify-content-center">
                  <div class="form-group">
                    <label class="text-center">Enter Password</label>
                    <input
                      type="password"
                      v-model="form.password"
                      class="form-control"
                      maxlength="15"
                      minlength="4"
                      required
                      placeholder="Enter ..."
                    />
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <div class="form-group">
                    <label class="text-center">Repeat Password</label>

                    <input
                      type="password"
                      v-model="form.confirm_password"
                      class="form-control"
                      maxlength="15"
                      minlength="4"
                      required
                      placeholder="Repeat ..."
                    />
                  </div>
                </div>

                <!-- password -->

                <div class="col-12 mt-3 d-flex justify-content-center">
                  <button
                    type="submit"
                    :disabled="form.busy"
                    class="btn custom-button-1"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </form>

      <Footer
        v-if="$gate.isCustomer() || $gate.isStore()"
        :getUserProfile="getUserProfile"
      />
      <AgencyFooter
        v-else-if="$gate.isAgency()"
        :getUserProfile="getUserProfile"
      />
    </div>
    <div v-else>
      <h1>Here</h1>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetPassword",
  components: {},
  data() {
    return {
      form: new Form({
        password: "",
        confirm_password: "",
      }),
    };
  },
  created() {},

  methods: {
    sendDetails() {
      //  run fields error checks method

      // if(this.errorChecks() == false)
      //     return
      this.$isLoading(true);

      if (!this.$gate.isPasswordSetAfterLogin()) {
        this.form
          .post("api/profile/setPassword", {
            headers: { "Content-Type": "application/json" },
          })
          .then((data) => {
            this.$isLoading(false);

            const confirmBox2 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2",
              },
              buttonsStyling: false,
            });

            let temp_text = "";

            confirmBox2
              .fire({
                icon: "success",
                title: "password set!",
                text: "New password is set, you can now set your pin",
              })
              .then((res) => {
                // redirect user to dashboard
                if (this.$gate.isAgent())
                  window.location.href = "/agent-dashboard";
                else if (this.$gate.isAgentAdmin())
                  window.location.href = "/agent-admin-dashboard";
                else if (this.$gate.isCustomer())
                  window.location.href = "/dashboard";
                else if (this.$gate.isMerchantAdmin())
                  window.location.href = "/merchant-admin-dashboard";
                else if (this.$gate.isCashdistributor())
                  window.location.href = "/distributor-dashboard";
                else if (this.$gate.isCashcollector())
                  window.location.href = "/collector-dashboard";
              });
          })
          .catch((err) => {
            this.$isLoading(false);

            const confirmBox2 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button bg-danger",
              },
              buttonsStyling: true,
            });

            confirmBox2.fire({
              icon: "error",
              title: "Failed!",
              text: "Your password registration didn't work!",
            });
            this.form.password = "";
            this.form.confirm_password = "";
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch("userProfile");
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
  },
};
</script>

