<template>
  <div>
    <div v-if="$gate.isBroker()">
      <!-- <div v-if="unusualTransactionNotificationArr.length">
                <div class="alert alert-danger" v-for="unusualTransactionNotification in unusualTransactionNotificationArr">
                    {{unusualTransactionNotification.name}} has some unusual transactions
                    <button @click="markAsRead" style="margin-top: -5px; font-size: 12px" class="btn btn-xs btn-success float-right">
                            Mark As read
                    </button>
                </div>
            </div> -->

      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Fee Earnings</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ broker_balance }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Current Broker Balance</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ currentTotalBrokerBalance }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Promotional Balance</span>
          </div>
          <div class="d-flex align-items-center">
            <span class="font-size-4 text-color-2 font-weight-bold">{{
              promotionalBalance
            }}</span>
            <div class="d-flex ml-2">
              <a
                href="#"
                data-toggle="modal"
                data-target="#increasePromoBalanceModal"
                class="btn btn-success rounded-circle mr-2"
              >
                <i class="fas fa-plus"></i>
              </a>
              <a class="btn btn-danger rounded-circle">
                <i class="fas fa-minus"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Order Transaction Fee Earnings</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ order_transactions_fee_balance }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Order Transaction Completed</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ order_transactions }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Card Fee Earnings</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ cards_fee_balance }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Total Card Deposit Completed</span>
          </div>
          <div>
            <span
              class="font-size-4 text-color-2 float-right font-weight-bold"
              >{{ cards_deposites }}</span
            >
          </div>
        </div>
      </div>
      <div class="card">
        <div class="d-flex justify-content-between px-3 py-2">
          <div class="d-flex align-items-center">
            <span class="font-size-1">Cash Power Sales</span>
          </div>
          <div>
            <span class="font-size-3 text-color-2 float-right font-weight-bold">
              {{ this.dalasisFormatter(cashpowerSum) }}</span
            >
          </div>
        </div>
      </div>

      <div class="row mx-1">
        <div class="card col-md-5">
          <div class="d-flex justify-content-between px-3 py-2">
            <div class="d-flex align-items-center">
              <span class="font-size-1">CashPower Fee Earnings</span>
            </div>
            <div>
              <span
                class="font-size-1 text-color-2 float-right font-weight-bold"
                >{{ cashpower_fee_earnings }}</span
              >
            </div>
          </div>
        </div>
        <div class="card offset-2 col-5">
          <div class="d-flex justify-content-between px-3 py-2">
            <div class="d-flex align-items-center">
              <span class="font-size-1">Airtime Fee Earnings</span>
            </div>
            <div>
              <span
                class="font-size-1 text-color-2 float-right font-weight-bold"
                >{{ airtime_fee_earnings }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 border-bottom">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          Daily Stats
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["fee_earnings"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"
                >Today's earnings (Money Transfer)</span
              >
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["fee_earnings"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon" style="color: #9584e4"
              ><i class="fas fa-hourglass-half"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["pending"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"> Pending </span>

              <span class="info-box-number">{{
                dalasisFormatter(basicStats["pending"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon text-success"
              ><i class="fas fa-thumbs-up"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["collected"]["count"]
                }}</span>
              </span>
              <span class="info-box-text">Successful Transactions</span>
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["collected"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-times"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Cancelled</span>
              <span class="info-box-number">{{
                dalasisFormatter(basicStats["cancelled"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
      </div>

      <!--  weekly starts here -->

      <div class="row mt-3 border-bottom">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          Weekly Stats
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  weeklyStats["fee_earnings"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">This week's earnings</span>
              <span class="info-box-number">{{
                dalasisFormatter(weeklyStats["fee_earnings"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon" style="color: #9584e4"
              ><i class="fas fa-hourglass-half"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  weeklyStats["pending"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"> Pending </span>

              <span class="info-box-number">{{
                dalasisFormatter(weeklyStats["pending"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon text-success"
              ><i class="fas fa-thumbs-up"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  weeklyStats["collected"]["count"]
                }}</span>
              </span>
              <span class="info-box-text">Successful Transactions</span>
              <span class="info-box-number">{{
                dalasisFormatter(weeklyStats["collected"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-times"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  weeklyStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Cancelled</span>
              <span class="info-box-number">{{
                dalasisFormatter(weeklyStats["cancelled"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
      </div>

      <!-- weekly starts ends here -->

      <!--  monthly starts here -->

      <div class="row mt-3 border-bottom">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          Monthly Stats
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-hand-holding-usd"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  monthlyStatsTransactions["fee_earnings"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">This month's earnings</span>
              <span class="info-box-number">{{
                dalasisFormatter(
                  monthlyStatsTransactions["fee_earnings"]["sum"]
                )
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon" style="color: #9584e4"
              ><i class="fas fa-hourglass-half"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  monthlyStatsTransactions["pending"]["count"]
                }}</span>
              </span>

              <span class="info-box-text"> Pending </span>

              <span class="info-box-number">{{
                dalasisFormatter(monthlyStatsTransactions["pending"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->

        <!-- fix for small devices only -->
        <div class="clearfix hidden-md-up"></div>

        <!-- /.col -->
        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box mb-3">
            <span class="info-box-icon text-success"
              ><i class="fas fa-thumbs-up"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  monthlyStatsTransactions["collected"]["count"]
                }}</span>
              </span>
              <span class="info-box-text">Successful Transactions</span>
              <span class="info-box-number">{{
                dalasisFormatter(monthlyStatsTransactions["collected"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-times"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  monthlyStatsTransactions["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Cancelled</span>
              <span class="info-box-number">{{
                dalasisFormatter(monthlyStatsTransactions["cancelled"]["sum"])
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
      </div>

      <!-- ends here -->

      <!--  monthly starts here -->

      <!-- Airtime ends here -->

      <div class="row mt-3 border-bottom">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          Airtime Sales
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><img
                src="images/africell.png"
                class="rounded-circle"
                style="max-width: 55px; max-height: 55px"
            /></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Africell</span>
              <span class="info-box-number">{{ `D ${africellSales}` }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><img
                src="images/qcell.jpeg"
                class="rounded-circle"
                style="max-width: 55px; max-height: 55px"
            /></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Qcell</span>
              <span class="info-box-number">{{ `D ${qcellSales}` }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><img
                src="images/gamcel.png"
                class="rounded-circle"
                style="max-width: 55px; max-height: 55px"
            /></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Gamcel</span>
              <span class="info-box-number">{{ `D ${gamcelSales}` }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger">
              <img
                src="images/comium.png"
                class="rounded-circle"
                style="max-width: 55px; max-height: 55px"
              />
            </span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Commium</span>
              <span class="info-box-number">{{ `D ${commiumSales}` }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <!-- /.col -->
      </div>

      <!-- Airtime ends here -->

      <div class="row mt-3 border-bottom">
        <div class="col-12 pb-2 text-muted font-weight-bold text-center">
          miscellaneous
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-users"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Total Customers</span>
              <span class="info-box-number">{{ totalCustomers }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-brown1"
              ><i class="fas fa-users"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Total Merchants</span>
              <span class="info-box-number">{{ totalMerchants }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-danger"
              ><i class="fas fa-building"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Total Agencies</span>
              <span class="info-box-number">{{ totalAgencyCount }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-success"
              ><i class="fas fa-money-check-alt"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Avg customer balance</span>
              <span class="info-box-number">{{
                avgCustomerBrokerBalance
              }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-warning"
              ><i class="fas fa-users"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Total Distributors</span>
              <span class="info-box-number">{{ totalCollectors }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>

        <div class="col-12 col-sm-6 col-md-3">
          <div class="info-box">
            <span class="info-box-icon text-info"
              ><i class="fas fa-users"></i
            ></span>

            <div class="info-box-content">
              <span class="info-box-text">
                <span class="btn-outline-info">{{
                  basicStats["cancelled"]["count"]
                }}</span>
              </span>

              <span class="info-box-text">Total Collectors</span>
              <span class="info-box-number">{{ totalCollectors }}</span>
            </div>
            <!-- /.info-box-content -->
          </div>
          <!-- /.info-box -->
        </div>
        <!-- /.col -->
      </div>

      <div class="mt-3 d-flex justify-content-end">
        <div class="">
          <label class="" for="">Select Stats Type</label>
          <select v-model="selectedStat" id="" class="form-control">
            <option value="Agency Stats">Agency</option>
            <option value="Customer Stats">Customer</option>
            <option value="Merchant Stats">Merchants</option>
            <option value="CashPowerSales Stats">CashPower Sales</option>
            <option value="AirtimeSales Stats">Airtime sales</option>
          </select>
        </div>
      </div>

      <div class="text-center text-lg font-weight-bold text-muted">
        <span>{{ selectedStat }}</span>
      </div>

      <div v-if="selectedStat == 'Agency Stats'" class="row mt-3">
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Fee Earnings (Money Transfer)</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selected"
                  @selected="setSelected"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Broker's Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(15, 45, 54, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["broker"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Agencies' Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(63, 146, 194)"
                    >
                      {{
                        dalasisFormatter(
                          chart1Selection["agencies"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <STChart :chartData="chart1Selection" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4">
          <!-- DIRECT CHAT -->
          <div class="card direct-chat direct-chat-primary">
            <div class="card-header bg-white">
              <h3 class="card-title">Agency Rankings</h3>

              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-toggle="tooltip"
                  title="Contacts"
                  data-widget="chat-pane-toggle"
                >
                  <i class="fas fa-medal"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="remove"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
              <!-- Conversations are loaded here -->
              <div class="direct-chat-messages">
                <div class="card-body table-responsive p-0">
                  <table class="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Company</th>
                        <th>{{ rankBy }} Amount</th>
                        <!-- <th>More</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rank, index) in agencyRankings" :key="index">
                        <td>{{ ++index }}</td>
                        <td>
                          <i
                            v-if="index <= 3"
                            :class="[
                              index == 1
                                ? 'text-orange'
                                : index == 2
                                ? 'text-gray'
                                : index == 3
                                ? 'text-bronze'
                                : '',
                              'fas fa-crown',
                            ]"
                          >
                          </i>

                          <router-link v-bind:to="'/view-agency/' + rank.id">
                            {{ rank.company_name }}
                          </router-link>
                        </td>
                        <td>
                          {{ dalasisFormatter(rank.amount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--/.direct-chat-messages-->

              <!-- Contacts are loaded here -->
              <div class="direct-chat-contacts">
                <ul class="contacts-list">
                  <li><span>Rank by</span></li>
                  <li class="ml-3">
                    <a
                      @click="setRankBy('Incoming')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Incoming
                    </a>
                  </li>
                  <li class="ml-3">
                    <a
                      @click="setRankBy('Outgoing')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Outgoing
                    </a>
                  </li>
                  <li class="ml-3">
                    <a
                      @click="setRankBy('Deposits')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Deposits
                    </a>
                  </li>
                  <!-- End Contact Item -->
                </ul>
                <!-- /.contacts-list -->
              </div>
              <!-- /.direct-chat-pane -->
            </div>
            <!-- /.card-body -->

            <!-- /.card-footer-->
          </div>
          <!--/.direct-chat -->
        </div>
      </div>

      <div v-if="selectedStat == 'CashPowerSales Stats'" class="row mt-3">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Cash Power Sales</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selectedCashPowerSales"
                  @selected="setSelectedCashPowerSales"
                >
                </TabNav>
              </div>

              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Total Sales: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(15, 45, 54, 1)"
                    >
                      {{
                        dalasisFormatter(
                          cashPowerSaleSelection["sale"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <CSChart :chartData="cashPowerSaleSelection" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="selectedStat == 'Customer Stats'" class="row mt-3">
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Deposits</h3>
              </div>
              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart3Title }}
                </div>

                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Completed Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(50, 109, 76, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart3Selection["completed"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Pending Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(240, 147, 57, 0.8)"
                    >
                      {{
                        dalasisFormatter(
                          chart3Selection["pending"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Cancelled Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(188, 38, 37, 1)"
                    >
                      {{
                        dalasisFormatter(
                          chart3Selection["cancelled"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <BChart3 :chartData="chart3Selection" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4">
          <div class="">
            <div class="card ml-lg-2">
              <div>
                <div class="text-center py-3 font-weight-bold">
                  Customer Transfers
                </div>
                <div class="d-flex justify-content-between flex-wrap mb-4 px-2">
                  <div
                    v-for="(label, index) in chart2Selection.labels"
                    :key="index"
                  >
                    <span class="text-muted">{{ label }}: </span>
                    <span
                      class="font-weight-bold"
                      :style="{
                        color: chart2Selection.colors[index],
                      }"
                    >
                      {{ dalasisFormatter(chart2Selection.transfers[index]) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="px-3">
                <BChart2 :chartData="chart2Selection" :height="200" />
              </div>
            </div>
          </div>

          <div class="">
            <!-- DIRECT CHAT -->
            <div class="card direct-chat direct-chat-primary">
              <div class="card-header bg-white">
                <h3 class="card-title">Customer Rankings</h3>

                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fas fa-minus"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-toggle="tooltip"
                    title="Contacts"
                    data-widget="chat-pane-toggle"
                  >
                    <i class="fas fa-medal"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="remove"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                <!-- Conversations are loaded here -->
                <div class="direct-chat-messages">
                  <div class="card-body table-responsive p-0">
                    <table class="table table-striped table-valign-middle">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Username</th>
                          <th>
                            {{ rankCustomerBy }}
                            Amount
                          </th>
                          <!-- <th>More</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(rank, index) in customerRankingsArr"
                          :key="index"
                        >
                          <td>{{ ++index }}</td>
                          <td>
                            {{ rank.first_name }}
                          </td>
                          <td class="text-center">
                            {{ dalasisFormatter(rank.amount) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--/.direct-chat-messages-->

                <!-- Contacts are loaded here -->
                <div class="direct-chat-contacts">
                  <ul class="contacts-list">
                    <li><span>Rank by</span></li>
                    <li class="ml-3">
                      <a
                        @click="setCustomerRankBy('customer_broker_balance')"
                        href="#"
                        data-toggle="tooltip"
                        title="Contacts"
                        data-widget="chat-pane-toggle"
                      >
                        Broker Balance
                      </a>
                    </li>
                    <li class="ml-3">
                      <a
                        @click="setCustomerRankBy('customer_deposits')"
                        href="#"
                        data-toggle="tooltip"
                        title="Contacts"
                        data-widget="chat-pane-toggle"
                      >
                        Highest depositor
                      </a>
                    </li>
                    <li class="ml-3">
                      <a
                        @click="setCustomerRankBy('customer_withdraws')"
                        href="#"
                        data-toggle="tooltip"
                        title="Contacts"
                        data-widget="chat-pane-toggle"
                      >
                        Highest Withdrawer
                      </a>
                    </li>
                    <!-- End Contact Item -->
                  </ul>
                  <!-- /.contacts-list -->
                </div>
                <!-- /.direct-chat-pane -->
              </div>
              <!-- /.card-body -->

              <!-- /.card-footer-->
            </div>
            <!--/.direct-chat -->
          </div>

          <!-- customer ranking added by baba ends here -->
        </div>
      </div>

      <div v-else-if="selectedStat == 'Merchant Stats'" class="row mt-3">
        <div class="col-md-12 col-lg-8">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Deposits</h3>
              </div>
              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart3Title }}
                </div>

                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Completed Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(50, 109, 76, 1)"
                    >
                      {{
                        dalasisFormatter(
                          merchantChartSelection["completed"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Pending Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(240, 147, 57, 0.8)"
                    >
                      {{
                        dalasisFormatter(
                          merchantChartSelection["pending"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Cancelled Total: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(188, 38, 37, 1)"
                    >
                      {{
                        dalasisFormatter(
                          merchantChartSelection["cancelled"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <BChart4 :chartData="merchantChartSelection" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-4">
          <div class="card direct-chat direct-chat-primary">
            <div class="card-header bg-white">
              <h3 class="card-title">Merchant Rankings</h3>

              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-toggle="tooltip"
                  title="Contacts"
                  data-widget="chat-pane-toggle"
                >
                  <i class="fas fa-medal"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="remove"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="direct-chat-messages">
                <div class="card-body table-responsive p-0">
                  <table class="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Merchant</th>
                        <th>{{ rankMerchantBy }} Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(rank, index) in merchantRankingsArr"
                        :key="index"
                      >
                        <td>{{ ++index }}</td>
                        <td>
                          <i
                            v-if="index <= 3"
                            :class="[
                              index == 1
                                ? 'text-orange'
                                : index == 2
                                ? 'text-gray'
                                : index == 3
                                ? 'text-bronze'
                                : '',
                              'fas fa-crown',
                            ]"
                          >
                          </i>

                          <router-link v-bind:to="'/view-agency/' + rank.id">
                            {{ rank.company_name }}
                          </router-link>
                        </td>
                        <td>
                          {{ dalasisFormatter(rank.amount) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="direct-chat-contacts">
                <ul class="contacts-list">
                  <li><span>Rank by</span></li>
                  <li class="ml-3">
                    <a
                      @click="setMerchantRankBy('Incoming')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Incoming
                    </a>
                  </li>
                  <li class="ml-3">
                    <a
                      @click="setMerchantRankBy('Outgoing')"
                      href="#"
                      data-toggle="tooltip"
                      title="Contacts"
                      data-widget="chat-pane-toggle"
                    >
                      Outgoing
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedStat == 'AirtimeSales Stats'" class="row mt-3">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="row">
              <div class="col-12">
                <h3 class="p-3 card-title">Airtime Sales</h3>
              </div>
              <div class="col-12 text-muted">
                <TabNav
                  :tabs="[
                    'Last Year',
                    'This Year',
                    'Monthly',
                    'Weekly',
                    'Daily',
                  ]"
                  :selected="selectedAirtimeSales"
                  @selected="setSelectedAirtimeSales"
                >
                </TabNav>
              </div>
              <div class="col-12 pt-2">
                <div class="text-center py-3 font-weight-bold">
                  {{ chart1Title }}
                </div>
                <div class="mb-4 ml-3">
                  <div>
                    <span class="text-muted">Africell's Total Sale: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(238, 130, 238)"
                    >
                      {{
                        dalasisFormatter(
                          airTimeSaleSelection["africellSale"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Qcell's Total Sale: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(63, 146, 194)"
                    >
                      {{
                        dalasisFormatter(
                          airTimeSaleSelection["qcellSale"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Gamcel's Total Sale: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgba(0, 0, 0, 0.6)"
                    >
                      {{
                        dalasisFormatter(
                          airTimeSaleSelection["gamcelSale"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div>
                    <span class="text-muted">Commium's Total Sale: </span>
                    <span
                      class="font-weight-bold"
                      style="color: rgb(66, 183, 119)"
                    >
                      {{
                        dalasisFormatter(
                          airTimeSaleSelection["commiumSale"].reduce(
                            (total, num) => total + num,
                            0
                          )
                        )
                      }}
                    </span>
                  </div>
                </div>
                <ATChart :chartData="airTimeSaleSelection" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modals -->
      <div
        class="modal fade"
        id="increasePromoBalanceModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="increaseCashOnHandModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form @submit.prevent="increasePromotionalBalance" method="post">
              <div class="modal-header border-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center">
                  <h2 class="swal2-title" id="exampleModalLongTitle">
                    Update promo balance by:
                  </h2>
                </div>
                <div class="input-group pt-2 px-3 mb-2">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text font-weight-bold text-navy bg-olive"
                      >D</span
                    >
                  </div>
                  <input
                    type="number"
                    required
                    id="amount"
                    placeholder="Enter amount in dalasis"
                    class="form-control"
                    v-model="promo_balance_form.amount"
                  />
                </div>
              </div>
              <div class="modal-footer border-0 d-flex justify-content-center">
                <button type="submit" class="btn custom-button-2">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <not-found></not-found>
    </div>
  </div>
</template>

<script>
import STChart from "../charts/broker/STChart.vue";
import CSChart from "../charts/broker/CSChart";
import BChart2 from "../charts/broker/BChart2.vue";
import BChart3 from "../charts/broker/BChart3.vue";
import ATChart from "../charts/broker/ATChart.vue";

import Tab from "../includes/tabs.vue";
import TabNav from "../includes/tabNav.vue";
import BChart4 from "../charts/broker/BChart4.vue";

export default {
  name: "BrokerDashboard",
  components: {
    STChart,
    TabNav,
    Tab,
    BChart2,
    BChart3,
    BChart4,
    CSChart,
    ATChart,
  },
  data() {
    return {
      cashpowerSum: "",
      africellSales: "",
      gamcelSales: "",
      commiumSales: "",
      qcellSales: "",
      unusualTransactionNotificationArr: [],
      totalAgencyCount: 0,
      totalCustomers: 0,
      totalMerchants: 0,
      totalDistributors: 0,
      totalCollectors: 0,
      avgCustomerBrokerBalance: 0,
      selected: "Daily",
      selectedCashPowerSales: "Daily",
      selectedAirtimeSales: "Daily",
      selectedStat: "Agency Stats",

      userInfo: "",
      basicStats: {},
      weeklyStats: {},
      monthlyStatsTransactions: {},
      broker_balance: "",
      currentTotalBrokerBalance: "",
      promotionalBalance: "",
      cashpower_fee_earnings: "",
      airtime_fee_earnings: "",
      cards_fee_balance: "",
      cards_deposites: "",
      order_transactions: "",
      order_transactions_fee_balance: "",
      commission: "",
      chart1Title: "Daily Fee Earnings",
      chart3Title: "Money Deposited This Year",
      chart1Selection: {
        broker: [0],
        agencies: [0],
      },
      chart2Selection: {
        colors: [],
        transfers: [0],
        labels: [],
      },
      chart3Selection: {
        completed: [0],
        cancelled: [0],
        pending: [0],
      },
      merchantChartSelection: {
        completed: [0],
        cancelled: [0],
        pending: [0],
      },
      cashPowerSaleSelection: {
        sale: [0],
      },
      airTimeSaleSelection: {
        africellSale: [0],
        qcellSale: [0],
        gamcelSale: [0],
        commiumSale: [0],
      },
      isFetched: {
        customer: false,
      },

      agencyRankings: [],
      merchantRankingsArr: [],
      customerRankingsArr: [],
      rankBy: "Incoming",
      rankCustomerBy: "customer_broker_balance",
      rankMerchantBy: "Incoming",

      promo_balance_form: new Form({
        amount: 0,
        password: "",
      }),
    };
  },

  created() {
    if (this.$gate.isBroker()) {
      // this.customerRankings();
      this.getAvgCustomerBalance();
      this.getCustomerCount();
      this.getMerchantCount();
      this.getAgenciesCount();
      this.getDistributorCount();
      this.getCollectorCount();
      this.getAirtimeSales();
      this.dashboardStats();
      this.dashboardWeeklyStats();
      this.monthlyStats();
      //   this.rankings()
      // this.merchantRankings();
      this.basicStats = {
        fee_earnings: {
          count: 0,
          sum: 0,
        },
        cancelled: {
          count: 0,
          sum: 0,
        },
        pending: {
          count: 0,
          sum: 0,
        },

        collected: {
          count: 0,
          sum: 0,
        },
      };

      this.weeklyStats = {
        fee_earnings: {
          count: 0,
          sum: 0,
        },
        cancelled: {
          count: 0,
          sum: 0,
        },
        pending: {
          count: 0,
          sum: 0,
        },

        collected: {
          count: 0,
          sum: 0,
        },
      };

      this.monthlyStatsTransactions = {
        fee_earnings: {
          count: 0,
          sum: 0,
        },
        cancelled: {
          count: 0,
          sum: 0,
        },
        pending: {
          count: 0,
          sum: 0,
        },

        collected: {
          count: 0,
          sum: 0,
        },
      };
    }

    // fetch available locations (will use coordinate system one day) or user addresses
  },

  watch: {
    getUserProfile: function () {
      this.userInfo = this.getUserProfile;
      this.broker_balance = this.dalasisFormatter(
        //// this is exactly the total fee earnings
        this.userInfo.current_balance
      );
      this.currentTotalBrokerBalance = this.dalasisFormatter(
        this.userInfo.currentTotalBrokerBalance
      );
      this.promotionalBalance = this.dalasisFormatter(
        this.userInfo.promotional_balance
      );

      this.cashpower_fee_earnings = this.dalasisFormatter(
        this.userInfo.cash_power_fee_earnings
      );
      this.airtime_fee_earnings = this.dalasisFormatter(
        this.userInfo.airtime_fee_earnings
      );
      this.cards_deposites = this.dalasisFormatter(
        this.userInfo.cards_deposites
      );
      this.cards_fee_balance = this.dalasisFormatter(
        this.userInfo.cards_fee_balance
      );
      this.order_transactions = this.dalasisFormatter(
        this.userInfo.order_transactions
      );
      this.order_transactions_fee_balance = this.dalasisFormatter(
        this.userInfo.order_transactions_fee_balance
      );
      

      console.log("total", this.getUserProfile);
      // this.commission = this.dalasisFormatter(this.userInfo.company_commission);
    },
    getBrokerChart1: function () {
      this.chartData = this.getBrokerChart1;
      this.chart1Selection = this.getBrokerChart1["daily"];
    },
    getCashPowerChart1: function () {
      this.chartData = this.getCashPowerChart1;
      this.cashPowerSaleSelection = this.getCashPowerChart1["daily"];
    },
    getAirtimeChart1: function () {
      this.chartData = this.getAirtimeChart1;
      this.airTimeSaleSelection = this.getAirtimeChart1["daily"];
      console.log("airtime sales", this.airTimeSaleSelection);
    },
    selected: function () {
      if (this.selected == "Daily") {
        this.chart1Selection = this.getBrokerChart1["daily"];
        this.chart1Title = "Daily Fee Earnings";
        this.chart1BrokerTotal = this.getBrokerChart1["daily"]["broker"];
      } else if (this.selected == "Weekly") {
        this.chart1Selection = this.getBrokerChart1["weekly"];
        this.chart1Title = "Weekly Fee Earnings";
      } else if (this.selected == "Monthly") {
        this.chart1Selection = this.getBrokerChart1["monthly"];
        this.chart1Title = "Monthly Fee Earnings";
      } else if (this.selected == "This Year") {
        this.chart1Selection = this.getBrokerChart1["yearly"];
        this.chart1Title = "This Year's Fee Earnings";
      } else if (this.selected == "Last Year") {
        this.chart1Selection = this.getBrokerChart1["last year"];
        this.chart1Title = "Last Year's Earnings";
      }
    },

    selectedCashPowerSales: function () {
      if (this.selectedCashPowerSales == "Daily") {
        this.cashPowerSaleSelection = this.getCashPowerChart1["daily"];
        this.chart1Title = "Daily CashPower Sales";
        // this.chart1BrokerTotal = this.getCashPowerChart1["daily"]["broker"];
      } else if (this.selectedCashPowerSales == "Weekly") {
        this.cashPowerSaleSelection = this.getCashPowerChart1["weekly"];
        this.chart1Title = "Weekly CashPower Sales";
        console.log(this.cashPowerSaleSelection);
      } else if (this.selectedCashPowerSales == "Monthly") {
        this.cashPowerSaleSelection = this.getCashPowerChart1["monthly"];
        this.chart1Title = "Monthly CashPower Sales";
      } else if (this.selectedCashPowerSales == "This Year") {
        this.cashPowerSaleSelection = this.getCashPowerChart1["yearly"];
        this.chart1Title = "This Year's CashPower Sales";
      } else if (this.selectedCashPowerSales == "Last Year") {
        this.cashPowerSaleSelection = this.getCashPowerChart1["last year"];
        this.chart1Title = "Last Year CashPower Sales";
      }
    },

    selectedAirtimeSales: function () {
      if (this.selectedAirtimeSales == "Daily") {
        this.airTimeSaleSelection = this.getAirtimeChart1["daily"];
        this.chart1Title = "Daily Airtime Sales";
        // this.chart1BrokerTotal = this.getCashPowerChart1["daily"]["broker"];
      } else if (this.selectedAirtimeSales == "Weekly") {
        this.airTimeSaleSelection = this.getAirtimeChart1["weekly"];
        this.chart1Title = "Weekly Airtime Sales";
        console.log(this.airTimeSaleSelection);
      } else if (this.selectedAirtimeSales == "Monthly") {
        this.airTimeSaleSelection = this.getAirtimeChart1["monthly"];
        this.chart1Title = "Monthly Airtime Sales";
      } else if (this.selectedAirtimeSales == "This Year") {
        this.airTimeSaleSelection = this.getAirtimeChart1["yearly"];
        this.chart1Title = "This Year's Airtime Sales";
      } else if (this.selectedAirtimeSales == "Last Year") {
        this.airTimeSaleSelection = this.getAirtimeChart1["last year"];
        this.chart1Title = "Last Year Airtime Sales";
      }
    },

    selectedStat: function () {
      if (this.selectedStat == "Customer Stats" && !this.isFetched.customer) {
        //   this.$isLoading(true)
        axios
          .get("api/broker-dashboard/chart2And3")
          .then(({ data }) => {
            console.log("chart data ", data);
            this.chart2Selection = data[0];
            this.chart3Selection = data[1];
            this.isFetched.customer = true;

            //  this.$isLoading(false)
          })
          .catch((err) => {
            //   this.$isLoading(false)
          });
      } else if (this.selectedStat == "Merchant Stats") {
        // broker-dashboard/merchantChart
        axios
          .get("api/broker-dashboard/merchantChart")
          .then(({ data }) => {
            console.log("merchant chart data ", data[0]);
            this.merchantChartSelection = data[0];
            // this.chart2Selection = data[0];
            // this.chart3Selection = data[1];
            // this.isFetched.customer = true;

            //   this.$isLoading(false)
          })
          .catch((err) => {
            //   this.$isLoading(false)
          });
      }
    },
  },

  mounted() {
    if (this.$gate.isBroker()) {
      this.$store.dispatch("userProfile");
      this.$store.dispatch("brokerChart1");

      axios
        .get("api/broker/unusualTransactionNotification")
        .then(({ data }) => {
          this.unusualTransactionNotificationArr = data.notifications;
          console.log(
            "unusual transactions",
            this.unusualTransactionNotificationArr
          );
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    }
  },
  computed: {
    getUserProfile() {
      //final output from here
      return this.$store.getters.getUserProfile;
    },
    getBrokerChart1() {
      console.log("chart1 data", this.$store.getters.getBrokerChart1);
      return this.$store.getters.getBrokerChart1;
    },
    getCashPowerChart1() {
      console.log("cashpower data", this.$store.getters.getCashPowerSalesData);
      return this.$store.getters.getCashPowerSalesData;
    },

    getAirtimeChart1() {
      console.log("airtime data", this.$store.getters.getAirtimeSalesData);
      return this.$store.getters.getAirtimeSalesData;
    },
  },

  methods: {
    increasePromotionalBalance() {
      console.log(this.getUserProfile);
      $("#increasePromoBalanceModal").modal("toggle");

      let new_cash =
        parseInt(this.promo_balance_form.amount) +
        parseInt(this.userInfo.promotional_balance);
      new_cash = this.dalasisFormatter(new_cash);

      const confirmBox1 = Swal.mixin({
        customClass: {
          confirmButton: "btn custom-button-2 mr-2",
          cancelButton: "btn custom-button bg-danger ml-2",
        },
        buttonsStyling: false,
      });

      confirmBox1
        .fire({
          title: "Are you sure?",
          text:
            "You'll be increasing your promo balance by " +
            this.dalasisFormatter(parseInt(this.promo_balance_form.amount)) +
            ". You new promo balance would be " +
            new_cash,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, increase it!",
        })
        .then((result1) => {
          if (result1.isConfirmed) {
            const box1 = Swal.mixin({
              customClass: {
                confirmButton: "btn custom-button-2 mr-2",
                denyButton: "btn custom-button bg-secondary ml-2",
              },
              buttonsStyling: false,
            });

            box1
              .fire({
                title: "Enter your password",
                input: "password",
                // inputAttributes: {
                //   maxlength: 4,
                //   minlength: 4,
                //   pattern: "[0-9]+",
                //   required: true,
                // },
                inputValidator: (value) => {
                  if (!value) {
                    return "You need to enter your password!";
                  }
                },
                showDenyButton: true,
                denyButtonText: `Back`,
                confirmButtonText: "Submit",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.promo_balance_form.password = result.value;

                  this.$isLoading(true);

                  this.promo_balance_form
                    .post("api/promotional-balance/increase", {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then((data) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button-2",
                        },
                        buttonsStyling: false,
                      });

                      confirmBox2
                        .fire({
                          icon: "success",
                          title: "Increase!",
                          text:
                            "Increment successful! Your new Cash Promo Balance " +
                            new_cash,
                        })
                        .then((res) => {
                          // reloading the page
                          window.location.href = "/broker-dashboard";
                          // this.cash_on_hand = new_cash
                        });
                    })
                    .catch((err) => {
                      this.$isLoading(false);

                      const confirmBox2 = Swal.mixin({
                        customClass: {
                          confirmButton: "btn custom-button bg-danger",
                        },
                        buttonsStyling: true,
                      });

                      confirmBox2.fire({
                        icon: "error",
                        title: "Failed!",
                        text: err.response.data.errors["pin"],
                      });
                    });
                } else if (result.isDenied) {
                  $("#increasePromoBalanceModal").modal("show");
                }
              });
          }
        });
    },
    markAsRead() {
      // mark the notification as read, hence removing it out here
      axios
        .get("api/broker/markNotificationAsRead")
        .then(({ data }) => {
          window.location.reload();
        })
        .catch((err) => {
          this.$isLoading(false);
        });
      console.log("mark as read");
    },

    setSelected(tab) {
      this.selected = tab;
    },

    setSelectedCashPowerSales(tab) {
      this.selectedCashPowerSales = tab;
    },

    setSelectedAirtimeSales(tab) {
      console.log("here we are hitting");
      this.selectedAirtimeSales = tab;
    },

    dashboardStats() {
      // fetch companies
      this.$isLoading(true);

      axios
        .get("api/broker-dashboard/basicStats")
        .then(({ data }) => {
          this.basicStats = data;
          this.rankings();
          this.customerRankings(); // the customerRank is called here which is called in the created
          this.merchantRankings();
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    monthlyStats() {
      // fetch companies
      this.$isLoading(true);

      axios
        .get("api/broker-dashboard/monthlyStats")
        .then(({ data }) => {
          console.log("monthly stats", data);
          this.monthlyStatsTransactions = data;
          this.rankings();
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    dashboardWeeklyStats() {
      // fetch companies
      this.$isLoading(true);

      axios
        .get("api/broker-dashboard/weeklyStats")
        .then(({ data }) => {
          console.log("weekly stats", data);
          this.weeklyStats = data;
          this.rankings();
          this.$isLoading(false);
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },

    rankings() {
      //   this.$isLoading(true)

      axios
        .get("api/broker-dashboard/agencyRankings/" + this.rankBy)
        .then(({ data }) => {
          console.log("ranking", data);
          this.agencyRankings = data;
          //   this.$isLoading(false)
        })
        .catch((err) => {
          //   this.$isLoading(false)
        });
    },
    customerRankings() {
      //   this.$isLoading(true)

      axios
        .get("api/broker-dashboard/customerRankings/" + this.rankCustomerBy)
        .then(({ data }) => {
          this.customerRankingsArr = data;
          // this.agencyRankings = data;
          //   this.$isLoading(false)
        })
        .catch((err) => {
          //   this.$isLoading(false)
        });
    },
    merchantRankings() {
      axios
        .get("api/broker-dashboard/merchantRankings/" + this.rankMerchantBy)
        .then(({ data }) => {
          this.merchantRankingsArr = data;
        })
        .catch((err) => {
          this.$isLoading(false);
        });
    },
    setRankBy(rb) {
      this.rankBy = rb;
      this.agencyRankings = [];
      this.rankings();
    },

    setCustomerRankBy(rb) {
      this.rankCustomerBy = rb;
      this.customerRankingsArr = [];
      this.customerRankings();
    },
    setMerchantRankBy(rb) {
      this.rankMerchantBy = rb;
      this.merchantRankingsArr = [];
      this.merchantRankings();
    },

    getCustomerCount() {
      axios
        .get("api/broker/customersCount")
        .then(({ data }) => {
          console.log("customers count", data.customerCount);
          this.totalCustomers = data.customerCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDistributorCount() {
      axios
        .get("api/broker/distributorsCount")
        .then(({ data }) => {
          console.log("distributors count", data.distributorsCount);
          this.totalDistributors = data.distributorsCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCollectorCount() {
      axios
        .get("api/broker/collectorsCount")
        .then(({ data }) => {
          console.log("collectors count", data.collectorsCount);
          this.totalCollectors = data.collectorsCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMerchantCount() {
      axios
        .get("api/broker/merchantCount")
        .then(({ data }) => {
          console.log("customers count", data.merchantCount);
          this.totalMerchants = data.merchantCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAirtimeSales() {
      axios
        .get("api/utilities/utilities-sales")
        .then(({ data }) => {
          this.africellSales = data.data[1].sum_amount;
          this.gamcelSales = data.data[0].sum_amount;
          this.qcellSales = data.data[3].sum_amount;
          this.commiumSales = data.data[2].sum_amount;
          this.cashpowerSum = data.data[4].cashpower_sum_amount;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAgenciesCount() {
      axios
        .get("api/broker/agenciesCount")
        .then(({ data }) => {
          console.log("agencies", data);
          this.totalAgencyCount = data.agenciesCount;
          //   this.pageData = data
          //     this.$isLoading(false)
        })
        .catch((err) => {
          //   this.agencies = []
          //   this.pageData = {}
          // this.$isLoading(false)
        });
    },

    getAvgCustomerBalance() {
      axios
        .get("api/broker/avgCustomerBalance")
        .then(({ data }) => {
          console.log("avg customer balance", data);
          this.avgCustomerBrokerBalance = data.avgCustomerBalance;
          //   this.pageData = data
          //     this.$isLoading(false)
        })
        .catch((err) => {
          //   this.agencies = []
          //   this.pageData = {}
          // this.$isLoading(false)
        });
    },
  },
};
</script>

<style scoped>
canvas.chartjs-render-monitor {
  display: block;
  width: 868px;
  height: 200px !important;
}
</style>
